import { SITE_CONFIG } from '@config/siteConfig';
import { CompanyImage } from '@domains/companies/sellerPage/components/CompanyImage/CompanyImage';
import { PhoneNumber } from '@domains/shared/components/PhoneNumber/PhoneNumber';
import type { JSX } from 'react';

import { StyledList, StyledParagraph, Wrapper } from './Header.theme';

interface Props {
    logo: string | null;
    companyName: string;
    phoneNumbers: string[];
    onPhoneNumberReveal?: (number: string) => void;
}

export const Header = ({ logo, companyName, phoneNumbers, onPhoneNumberReveal }: Props): JSX.Element => {
    return (
        <Wrapper>
            <CompanyImage src={logo} companyName={companyName} width="40" height="40" />
            <StyledParagraph>{companyName}</StyledParagraph>
            <StyledList>
                {phoneNumbers.map((number) => (
                    <li key={number}>
                        <PhoneNumber
                            number={number}
                            country={SITE_CONFIG.phoneNumbers.country}
                            nationalCountryCode={SITE_CONFIG.phoneNumbers.countryCode}
                            onRevealCallback={(): void => onPhoneNumberReveal?.(number)}
                        />
                    </li>
                ))}
            </StyledList>
        </Wrapper>
    );
};
