import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import type { JSX } from 'react';

import { getPaginationPages } from './getPaginationPages';
import { Control, ControlsWrapper } from './PaginationControls.theme';
import { PaginationControlsItem } from './PaginationControlsItem';

export type PaginationPosition = 'left' | 'center' | 'right';

export interface PaginationProps {
    activePage: number;
    entriesCount: number;
    perPage: number;
    position?: PaginationPosition;
    onPageChange(page: number, source: 'link' | 'button' | 'arrow'): void;
    dataCy?: string;
    shouldPagesBeLink?: boolean;
    shouldShallowLink?: boolean;
}

export const PaginationControls = ({
    activePage,
    entriesCount,
    perPage,
    position = 'center',
    onPageChange,
    dataCy = 'pagination',
    shouldPagesBeLink,
    shouldShallowLink,
}: PaginationProps): JSX.Element | null => {
    const [t] = useTranslations();

    if (!entriesCount) {
        return null;
    }

    const pagesCount = Math.ceil(entriesCount / perPage);

    if (pagesCount === 0) {
        return null;
    }

    const pages = getPaginationPages(activePage, pagesCount);
    const isPreviousDisabled = activePage <= 1;
    const isNextDisabled = activePage >= pagesCount;

    return (
        <ControlsWrapper
            data-cy={dataCy}
            role="navigation"
            aria-label={t('frontend.pagination-controls.navigation-label')}
            position={position}
        >
            <Control
                aria-label={t('frontend.pagination-controls.previous-page')}
                data-cy="pagination.previous-page"
                disabled={isPreviousDisabled}
                onClick={(): void => onPageChange(activePage - 1, 'arrow')}
            >
                <Icon icon={faChevronLeft} />
            </Control>
            {pages.map((page, index) => {
                const key = `pagination-${page}-${index}`;

                return (
                    <PaginationControlsItem
                        page={page}
                        key={key}
                        activePage={activePage}
                        shouldPagesBeLink={shouldPagesBeLink}
                        shouldShallowLink={shouldShallowLink}
                        onPageChange={onPageChange}
                    />
                );
            })}
            <Control
                aria-label={t('frontend.pagination-controls.next-page')}
                data-cy="pagination.next-page"
                disabled={isNextDisabled}
                onClick={(): void => onPageChange(activePage + 1, 'arrow')}
            >
                <Icon icon={faChevronRight} />
            </Control>
        </ControlsWrapper>
    );
};
