import { MEADOW, PUMPKIN } from '@lib/styles/partials/colors';

import type { ClipboardTheme } from './type';

export const CLIPBOARD_THEME: ClipboardTheme = {
    savedAds: {
        card: {
            priceDropInfo: {
                backgroundColor: MEADOW.x100,
                textColor: MEADOW.x600,
            },
            priceIncreaseInfo: {
                backgroundColor: PUMPKIN.x200,
                textColor: PUMPKIN.x500,
            },
        },
    },
};
