import { FieldError } from '@domains/shared/components/FieldError/FieldError';
import { EMAIL_REGULAR_EXPRESSION } from '@domains/shared/consts/regularExpressions';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import type { FocusEventHandler, JSX } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import type { DeprecatedFormData } from '../types/formData';
import { StyledInput } from './EmailField.theme';

interface Props {
    onFocus?: (fieldName: keyof DeprecatedFormData) => void;
    onBlur?: (fieldName: keyof DeprecatedFormData, isValid: boolean) => void;
}

const FIELD_NAME = 'email';

export const EmailField = ({ onFocus, onBlur }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { register, formState, setValue } = useFormContext<DeprecatedFormData>();
    const { isAwaitingUserData, user } = useLazyUser();

    const alertId = 'email-error-alert';
    const error = formState.errors.email;
    const hasUserEmail = !!user?.email;
    const label = t('frontend.ad.contact-form.field-placeholder-email');

    const registeredField = register(FIELD_NAME, {
        required: t('frontend.ad.contact-form.email-required-error'),
        pattern: {
            message: t('frontend.ad.contact-form.email-error'),
            value: EMAIL_REGULAR_EXPRESSION,
        },
    });

    const handleFocus: FocusEventHandler<HTMLInputElement> = (): void => {
        onFocus?.(FIELD_NAME);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = async (event): Promise<void> => {
        await registeredField.onBlur(event);
        // Note: We're not using `error` const because we need fresh reference.
        onBlur?.(FIELD_NAME, !formState.errors.email);
    };

    useEffect(() => {
        if (user?.email) {
            setValue(FIELD_NAME, user?.email);
        }
    }, [setValue, user?.email]);

    return (
        <div>
            <StyledInput
                {...registeredField}
                aria-label={label}
                aria-required="true"
                aria-errormessage={error ? alertId : undefined}
                placeholder={`${label}*`}
                readOnly={isAwaitingUserData || hasUserEmail}
                type="email"
                variant={error ? 'invalid' : 'default'}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {error ? <FieldError id={alertId} error={error.message} /> : null}
        </div>
    );
};
