import { TooltipDescription } from '@domains/shared/components/TooltipIcon/Tooltip.theme';
import { TooltipIcon } from '@domains/shared/components/TooltipIcon/TooltipIcon';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import type { CSSProperties } from 'react';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Price = styled.span`
    float: left;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.search.priceBeforeDiscount.price.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.regular};
    text-align: left;
    text-decoration: line-through;

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p3};
    }
`;

export const StyledTooltipIcon = styled(TooltipIcon)`
    margin-left: 8px;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.search.priceBeforeDiscount.tooltip.color};

    ${TooltipDescription} {
        text-align: left;
    }
`;
