import { FieldError } from '@domains/shared/components/FieldError/FieldError';
import { Input } from '@domains/shared/components/Input/Input';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FocusEventHandler, JSX } from 'react';
import { useFormContext } from 'react-hook-form';

import type { DeprecatedFormData } from '../types/formData';

interface Props {
    onFocus?: (fieldName: keyof DeprecatedFormData) => void;
    onBlur?: (fieldName: keyof DeprecatedFormData, isValid: boolean) => void;
}

export const NameField = ({ onFocus, onBlur }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { register, formState } = useFormContext<DeprecatedFormData>();

    const fieldName = 'name';
    const alertId = 'name-error-alert';
    const error = formState.errors.name;
    const label = t('frontend.ad.contact-form.field-placeholder-name');

    const registeredField = register(fieldName, {
        required: t('frontend.ad.contact-form.name-required-error'),
    });

    const handleFocus: FocusEventHandler<HTMLInputElement> = (): void => {
        onFocus?.(fieldName);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = async (event): Promise<void> => {
        await registeredField.onBlur(event);
        // Note: We're not using `error` const because we need fresh reference.
        onBlur?.(fieldName, !formState.errors.name);
    };

    return (
        <div>
            <Input
                {...registeredField}
                aria-errormessage={error ? alertId : undefined}
                aria-label={label}
                aria-required="true"
                placeholder={`${label}*`}
                type="text"
                variant={error ? 'invalid' : 'default'}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {error ? <FieldError id={alertId} error={error.message} /> : null}
        </div>
    );
};
