import { SITE_CONFIG } from '@config/siteConfig';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getFormattedPrice } from '@domains/shared/helpers/getFormattedPrice';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ListingItemPrice } from '@type/pricing/listingItemPrice';
import type { PriceChangeType } from '@type/pricing/price';
import type { PeoplePerRoom } from '@type/search/filters/peoplePerRoom';
import type { TransactionVariant } from '@type/search/transactionVariant';
import { TRANSACTION_VARIANT } from '@type/search/transactionVariant';
import type { ComponentProps, JSX } from 'react';
import { useContext } from 'react';

import { LowestPriceBeforeDiscount } from './components/LowestPriceBeforeDiscount';
import { PriceBeforeDiscount } from './components/PriceBeforeDiscount';
import {
    checkIsMetaArea,
    checkIsMetaAreaRange,
    checkIsMetaInvestmentEstimatedDelivery,
    checkIsMetaInvestmentUnits,
    checkIsMetaInvestmentUnitsRooms,
    checkIsMetaPricePerUnit,
    checkIsPriceListing,
    getRoomsNumberLabel,
} from './helpers';
import {
    CommonMeta,
    CommonMetaItem,
    CommonRent,
    MapMeta,
    MapMetaItem,
    MapRent,
    NewPriceBadge,
    NewPriceLabel,
} from './ListingItemMeta.theme';
import type {
    AdvertListItemMeta,
    MetaArea,
    MetaInvestmentEstimatedDelivery,
    MetaInvestmentUnits,
    MetaInvestmentUnitsRooms,
    MetaPricePerUnit,
} from './types';

const PEOPLE_PER_ROOM_TRANSLATIONS: Record<PeoplePerRoom, string> = {
    ['ONE']: 'frontend.shared.advert-item.people-per-room-one',
    ['TWO']: 'frontend.shared.advert-item.people-per-room-two',
    ['THREE']: 'frontend.shared.advert-item.people-per-room-three',
};

const getFormattedValue = (meta: unknown): string | JSX.Element => {
    if (
        !checkIsPriceListing(meta) &&
        !checkIsMetaArea(meta) &&
        !checkIsMetaPricePerUnit(meta) &&
        !checkIsMetaAreaRange(meta) &&
        !checkIsMetaInvestmentUnits(meta) &&
        !checkIsMetaInvestmentUnitsRooms(meta) &&
        !checkIsMetaInvestmentEstimatedDelivery(meta)
    ) {
        return `${meta}`;
    }

    if (checkIsMetaAreaRange(meta)) {
        const [from, to] = meta;

        return (
            <>
                {`${from.prefix} `}
                <strong>
                    {from.value} {from.unit}
                </strong>
                {` ${to.prefix} `}
                <strong>
                    {to.value} {to.unit}
                </strong>
            </>
        );
    }

    if (checkIsMetaArea(meta)) {
        if (!meta.value) {
            return '';
        }

        const base = `${meta.value} ${meta.unit}`;

        return meta.prefix ? `${meta.prefix} ${base}` : base;
    }

    if (checkIsMetaPricePerUnit(meta)) {
        return meta.price ? <strong>{`${getFormattedPrice(meta.price)}/${meta.unit}`}</strong> : '';
    }

    if (checkIsMetaInvestmentUnits(meta)) {
        return (
            <>
                {`${meta.prefix} `}
                <strong>{meta.value}</strong>
            </>
        );
    }

    if (checkIsMetaInvestmentUnitsRooms(meta)) {
        return (
            <>
                {`${meta.prefix} `}
                <strong>{meta.min === meta.max ? `${meta.min}` : `${meta.min} - ${meta.max}`}</strong>
            </>
        );
    }

    if (checkIsMetaInvestmentEstimatedDelivery(meta)) {
        return (
            <>
                {`${meta.prefix} `}
                <strong>{meta.value}</strong>
            </>
        );
    }

    return getFormattedPrice(meta);
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

interface Props extends ComponentProps<typeof CommonMeta> {
    price?: string;
    metaData: Pick<
        AdvertListItemMeta,
        | 'roomsNumber'
        | 'areaInSquareMeters'
        | 'pricePerSquareMeter'
        | 'investmentUnitsAreaInSquareMeters'
        | 'peoplePerRoom'
        | 'rentPrice'
    >;
    transactionVariant: TransactionVariant;
    priceBeforeChange?: string;
    priceChangeViewVariant?: 'SHORT' | 'FULL';
    priceChangeType?: PriceChangeType;
    priceChangeInPercents?: string;
    isAdRemoved?: boolean;
    shouldUseStylesForSingleElement?: boolean;
    shouldUseMapStyles?: boolean;
}

export const ListingItemMeta = ({
    price,
    metaData,
    transactionVariant,
    priceBeforeChange,
    priceChangeViewVariant = 'SHORT',
    priceChangeType,
    priceChangeInPercents,
    isAdRemoved,
    shouldUseStylesForSingleElement,
    shouldUseMapStyles,
    ...props
}: Props): JSX.Element => {
    const {
        defaultUnits: { area: areaUnit },
        shouldUseTypologyInsteadOfNumberOfRooms,
    } = SITE_CONFIG;

    const [t] = useTranslations();
    const {
        lang,
        featureFlags: { isListingRentPriceEnabled },
    } = useSiteSettings();
    const { isDesktop } = useContext(RWDContext);

    const {
        roomsNumber,
        areaInSquareMeters,
        pricePerSquareMeter,
        investmentUnitsAreaInSquareMeters,
        peoplePerRoom,
        rentPrice,
    } = metaData;
    const shouldShowRentPrice = TRANSACTION_VARIANT.flatRent === transactionVariant && isListingRentPriceEnabled;
    const formattedRentPrice = rentPrice
        ? `${t('frontend.search.item.rent')} ${getFormattedPrice(rentPrice)}/${t(
              'frontend.search.item.per-month-full',
          )}`
        : `${t('frontend.search.item.rent')} ${t('frontend.search.item.rent-find')}`;

    const rooms = getRoomsNumberLabel({ t, lang, roomsNumber, shouldUseTypologyInsteadOfNumberOfRooms });

    const metaAreaInSquareMeters: MetaArea | null =
        areaInSquareMeters && areaInSquareMeters > 0
            ? {
                  value: areaInSquareMeters.toLocaleString(lang),
                  unit: areaUnit,
              }
            : null;
    const metaPricePerSquareMeter: MetaPricePerUnit = {
        price: pricePerSquareMeter,
        unit: areaUnit,
    };
    const metaAreaRangeInSquareMeters: [MetaArea, MetaArea] | null = investmentUnitsAreaInSquareMeters
        ? [
              {
                  prefix: t('frontend.search.item.from'),
                  value: investmentUnitsAreaInSquareMeters.from.toLocaleString(lang),
                  unit: areaUnit,
              },
              {
                  prefix: t('frontend.search.item.to'),
                  value: investmentUnitsAreaInSquareMeters.to.toLocaleString(lang),
                  unit: areaUnit,
              },
          ]
        : null;

    const translatedPeoplePerRoom: string | null = peoplePerRoom && t(PEOPLE_PER_ROOM_TRANSLATIONS[peoplePerRoom]);
    const metaValues: Partial<
        Record<
            TransactionVariant,
            Array<
                | string
                | ListingItemPrice
                | MetaArea
                | MetaPricePerUnit
                | [MetaArea, MetaArea]
                | MetaInvestmentUnits
                | MetaInvestmentUnitsRooms
                | MetaInvestmentEstimatedDelivery
            >
        >
    > = {
        [TRANSACTION_VARIANT.flatSell]: [metaPricePerSquareMeter, rooms, metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.flatRent]: [rooms, metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.houseSell]: [metaPricePerSquareMeter, rooms, metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.houseRent]: [rooms, metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.roomRent]: [translatedPeoplePerRoom].filter(notEmpty),
        [TRANSACTION_VARIANT.terrainSell]: [metaPricePerSquareMeter, metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.terrainRent]: [metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.commercialPropertySell]: [metaPricePerSquareMeter, metaAreaInSquareMeters].filter(
            notEmpty,
        ),
        [TRANSACTION_VARIANT.commercialPropertyRent]: [metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.hallSell]: [metaPricePerSquareMeter, metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.hallRent]: [metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.garageSell]: [metaPricePerSquareMeter, metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.garageRent]: [metaAreaInSquareMeters].filter(notEmpty),
        [TRANSACTION_VARIANT.investment]: [metaAreaRangeInSquareMeters].filter(notEmpty),
    };
    const metaProperties = metaValues[transactionVariant] || [];
    const shouldShowShortDiscountInfo = priceBeforeChange && priceChangeViewVariant === 'SHORT';
    const shouldShowFullDiscountInfo =
        priceBeforeChange &&
        priceChangeViewVariant === 'FULL' &&
        priceChangeType &&
        priceChangeInPercents &&
        !isAdRemoved;
    const isPriceUp = priceChangeType === 'PRICE_UP';
    const shouldShowLowestPriceBeforeChange =
        shouldShowFullDiscountInfo && priceBeforeChange && !isPriceUp && isDesktop;
    const shouldShowRentPriceBelowParams =
        shouldShowRentPrice && (!shouldShowFullDiscountInfo || (shouldShowFullDiscountInfo && !isDesktop));
    const shouldShowRentPriceInlineWithParams = shouldShowRentPrice && shouldShowFullDiscountInfo && isDesktop;

    const Meta = shouldUseMapStyles ? MapMeta : CommonMeta;
    const MetaItem = shouldUseMapStyles ? MapMetaItem : CommonMetaItem;
    const Rent = shouldUseMapStyles ? MapRent : CommonRent;

    return (
        <Meta isAdRemoved={isAdRemoved} shouldUseStylesForSingleElement={shouldUseStylesForSingleElement} {...props}>
            {shouldShowShortDiscountInfo ? <PriceBeforeDiscount price={priceBeforeChange} /> : null}
            {shouldShowFullDiscountInfo ? (
                <NewPriceLabel isPriceUp={isPriceUp}>
                    {t('frontend.search.listing-meta-item.new-price-label')}
                </NewPriceLabel>
            ) : null}
            {price ? (
                <MetaItem shouldUseStylesForSingleElement={shouldUseStylesForSingleElement}>
                    {price}
                    {shouldShowRentPriceInlineWithParams ? <Rent isInlineWithPrice>{formattedRentPrice}</Rent> : null}
                    {shouldShowFullDiscountInfo && isDesktop ? (
                        <NewPriceBadge isPriceUp={isPriceUp} shouldShowRentPrice={!!shouldShowRentPrice}>
                            {priceChangeInPercents}
                        </NewPriceBadge>
                    ) : null}
                </MetaItem>
            ) : null}
            {metaProperties.map((meta: unknown, index) =>
                meta ? (
                    <MetaItem key={index} shouldUseStylesForSingleElement={shouldUseStylesForSingleElement}>
                        {getFormattedValue(meta)}
                    </MetaItem>
                ) : null,
            )}
            {shouldShowRentPriceBelowParams ? <Rent>{formattedRentPrice}</Rent> : null}
            {shouldShowLowestPriceBeforeChange ? <LowestPriceBeforeDiscount price={priceBeforeChange} /> : null}
        </Meta>
    );
};
