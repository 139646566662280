import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useContext } from 'react';

import { CoreContactForm } from '../core/CoreContactForm';
import { useFormEventHandlersFactory } from '../core/hooks/useFormEventHandlersFactory';
import { Header } from './components/Header';
import { StyledModal } from './ModalContactForm.theme';

interface Props {
    ownerId: string;
    logo: string | null;
    companyName: string;
    phoneNumbers: string[];
    onDismiss: () => void;
    shouldCloseModalAfterSuccess?: boolean;
    additionalTrackingData?: Record<string, unknown>;
}

export const ModalContactForm = ({
    ownerId,
    logo,
    companyName,
    phoneNumbers,
    onDismiss,
    shouldCloseModalAfterSuccess = false,
    additionalTrackingData = {},
}: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);

    const eventTouchPointButton = isDesktop ? 'contact_us_header' : 'mobile_footer';
    const formEventHandlers = useFormEventHandlersFactory({ eventTouchPointButton, additionalTrackingData });

    const handlePhoneNumberReveal = (): void => {
        trackEvent('reply_phone_show', {
            touch_point_button: eventTouchPointButton,
            ...additionalTrackingData,
        });
    };

    const handleOnRequestSuccess = (): void => {
        formEventHandlers.handleFormRequestSuccess();
        if (shouldCloseModalAfterSuccess) {
            onDismiss();
        }
    };

    return (
        <StyledModal isVisible isFullScreenOnMobile onDismiss={onDismiss}>
            <CoreContactForm
                ownerId={ownerId}
                header={
                    <Header
                        logo={logo}
                        companyName={companyName}
                        phoneNumbers={phoneNumbers}
                        onPhoneNumberReveal={handlePhoneNumberReveal}
                    />
                }
                shouldUseGdptPopover={false}
                onValid={formEventHandlers.handleFormValid}
                onInvalid={formEventHandlers.handleFormInvalid}
                onRequestSuccess={handleOnRequestSuccess}
                onRequestFailure={formEventHandlers.handleFormRequestFailure}
                onFieldFocus={formEventHandlers.handleFieldFocus}
                onFieldBlur={formEventHandlers.handleFieldBlur}
            />
        </StyledModal>
    );
};
