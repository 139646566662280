import { Button } from '@domains/shared/components/Button/Button';
import { LinkButton } from '@domains/shared/components/LinkButton/LinkButton';
import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

import type { PaginationPosition } from './PaginationControls';

interface ControlsWrapperProps {
    position: PaginationPosition;
}

const POSITION_TO_JUSTIFY_CONTENT_MAP: Record<PaginationPosition, string> = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

export const ControlsWrapper = styled.nav<ControlsWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ position }): string => POSITION_TO_JUSTIFY_CONTENT_MAP[position]};
`;

interface ControlProps {
    isActive?: boolean;
}

const getControlStyles = (styles: Theme, isActive?: boolean): SerializedStyles => css`
    margin: 0 5px;
    border: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
    border-color: ${styles.deprecated.domains.shared.paginationControls.control.default.borderColor};
    background-color: ${isActive
        ? styles.deprecated.domains.shared.paginationControls.control.active.backgroundColor
        : styles.deprecated.domains.shared.paginationControls.control.default.backgroundColor};
    color: ${isActive
        ? styles.deprecated.domains.shared.paginationControls.control.active.color
        : styles.deprecated.domains.shared.paginationControls.control.default.color};

    &:focus,
    &:active,
    &:hover {
        border-color: ${isActive
            ? 'inherit'
            : styles.deprecated.domains.shared.paginationControls.control.hover.borderColor};
        outline: none;
        background-color: ${isActive
            ? styles.deprecated.domains.shared.paginationControls.control.active.backgroundColor
            : styles.deprecated.domains.shared.paginationControls.control.default.backgroundColor};
        color: ${isActive
            ? styles.deprecated.domains.shared.paginationControls.control.active.color
            : styles.deprecated.domains.shared.paginationControls.control.default.color};
    }

    &:first-of-type:disabled,
    &:last-of-type:disabled,
    &:disabled:hover {
        border-color: ${styles.deprecated.domains.shared.paginationControls.control.disabled.borderColor};
        background-color: ${styles.deprecated.domains.shared.paginationControls.control.disabled.backgroundColor};
        color: ${styles.deprecated.domains.shared.paginationControls.control.disabled.color};
    }
`;

export const ControlLink = styled(LinkButton)<ControlProps>`
    ${({ theme, isActive }): SerializedStyles => getControlStyles(theme, isActive)}
`;
export const Control = styled(Button)<ControlProps>`
    ${({ theme, isActive }): SerializedStyles => getControlStyles(theme, isActive)}
    &:first-of-type {
        margin-left: 0;
    }
`;

export const ActiveControl = styled.span<ControlProps>`
    ${({ theme }): SerializedStyles => getControlStyles(theme, true)}

    display: inline-block;
    width: auto;
    margin: 0 5px;
    padding: ${theme.space[12]} ${theme.space[16]};
    transition: background-color 0.15s ease-in-out 0s;
    border: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
    border-radius: 4px;
    border-color: ${({ theme }): CSSProperties['borderColor'] =>
        theme.deprecated.domains.shared.paginationControls.control.default.borderColor};
    outline: none;
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights[600]};
    line-height: 1;
    text-align: center;
    cursor: pointer;
`;
export const Dots = styled.div`
    margin: 0;
    font-weight: ${theme.fontWeights[400]};

    @media (min-width: ${BREAKPOINT.sm}) {
        margin: 0 5px;
    }
`;
