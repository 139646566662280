import styled from '@emotion/styled';
import type { CSSProperties } from 'react';

export const HiddenNativeCheckbox = styled.input`
    position: absolute;
    width: 0 !important;
    height: 0 !important;
    outline: none;
    opacity: 0 !important;
`;

export const Thumb = styled.span`
    display: block;
    width: 22px;
    height: 22px;
    transform: translateX(1px);
    transition: transform 100ms;
    border-radius: 50%;
    background: ${({ theme }): CSSProperties['background'] =>
        theme.deprecated.domains.shared.toggle.thumb.backgroundColor};
    box-shadow: ${({ theme }): CSSProperties['boxShadow'] => theme.deprecated.domains.shared.toggle.thumb.boxShadow};
`;

export const Switch = styled.label`
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    width: 48px;
    height: 24px;
    border-radius: 15px;
    background: ${({ theme }): CSSProperties['background'] =>
        theme.deprecated.domains.shared.toggle.switch.notChecked.backgroundColor};
    cursor: pointer;

    input:focus + & {
        box-shadow: ${({ theme }): CSSProperties['boxShadow'] =>
            theme.deprecated.domains.shared.toggle.focus.boxShadow};
    }

    input:disabled + & {
        background: ${({ theme }): CSSProperties['background'] =>
            theme.deprecated.domains.shared.toggle.switch.disabled.backgroundColor};
        cursor: not-allowed;
    }

    input:checked + & {
        background: ${({ theme }): CSSProperties['background'] =>
            theme.deprecated.domains.shared.toggle.switch.checked.backgroundColor};

        ${Thumb} {
            transform: translateX(25px);
        }
    }
`;
