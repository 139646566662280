interface Props {
    page: number;
    path: string;
}

export const getPageHref = ({ page, path }: Props): string => {
    const [pathname, query] = path.split('?', 2);
    const queryParams = new URLSearchParams(query);

    if (page === 1) {
        queryParams.delete('page');
    } else {
        queryParams.set('page', `${page}`);
    }

    const queryString = queryParams.toString();

    return `${pathname}${queryString ? `?${queryString}` : ''}`;
};
