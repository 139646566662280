import { FieldError } from '@domains/shared/components/FieldError/FieldError';
import { createPhoneInputRegisterValidators } from '@domains/shared/components/PhoneInput/createPhoneInputRegisterValidators';
import type { OnBlurSplitInput, OnFocusSplitInput } from '@domains/shared/components/PhoneInput/PhoneInput';
import { PhoneInput } from '@domains/shared/components/PhoneInput/PhoneInput';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';
import { useFormContext } from 'react-hook-form';

import type { DeprecatedFormData } from '../types/formData';

interface Props {
    onFocus?: (fieldName: keyof DeprecatedFormData) => void;
    onBlur?: (fieldName: keyof DeprecatedFormData, isValid: boolean) => void;
}

export const PhoneNumberField = ({ onFocus, onBlur }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { register, formState } = useFormContext<DeprecatedFormData>();

    const fieldName = 'phone';
    const alertId = 'phone-number-error-alert';
    const error = formState.errors.phone;
    const labelTranslationKey = 'frontend.ad.contact-form.field-placeholder-phone';

    const handleFocusSplitInput: OnFocusSplitInput = (): void => {
        onFocus?.(fieldName);
    };

    const handleBlurSplitInput: OnBlurSplitInput = async (result): Promise<void> => {
        const { isCountryCodeValid, isPhoneNumberValid } = result;
        const isValid = isCountryCodeValid && isPhoneNumberValid;

        onBlur?.(fieldName, isValid);
    };

    return (
        <div>
            <PhoneInput
                {...register(fieldName, createPhoneInputRegisterValidators(t, { required: true }))}
                isCountryCodeEnabled
                isValid={!error}
                phoneNumberLabelTranslationKey={labelTranslationKey}
                placeholder={`${t(labelTranslationKey)}*`}
                required
                onFocusSplitInput={handleFocusSplitInput}
                onBlurSplitInput={handleBlurSplitInput}
            />
            {error ? <FieldError id={alertId} error={error.message} /> : null}
        </div>
    );
};
