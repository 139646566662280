import { SITE_CONFIG } from '@config/siteConfig';
import { SNACKBAR_ID } from '@domains/companies/sellerPage/constants';
import { SEND_MESSAGE_OWNER_MUTATION } from '@domains/companies/sellerPage/graphql/mutations/SendMessageOwnerMutation';
import { useContactFormTracking } from '@domains/companies/sellerPage/nexusComponents/ContactForm/hooks/useContactFormTracking';
import type { FormData } from '@domains/companies/sellerPage/nexusComponents/ContactForm/types';
import { toast } from '@domains/shared/components/Toast/toast';
import { logError } from '@domains/shared/helpers/logger';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { assertGraphqlResponse } from '@lib/graphql/assertGraphqlResponse';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { useSnackbarState } from '@nexus/lib-react/dist/core/Snackbar';
import type { BaseSyntheticEvent } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import type { DeprecatedFormData } from '../types/formData';

interface UseContactFormArguments {
    ownerId: string;
    onValid?: () => void;
    onInvalid?: () => void;
    onRequestSuccess?: () => void;
    onRequestFailure?: () => void;
}
interface UseContactFormReturnType extends UseFormReturn<DeprecatedFormData> {
    submit: (event?: BaseSyntheticEvent) => Promise<void>;
}

// TODO: to be removed in https://naspersclassifieds.atlassian.net/browse/EURE-28574
const isNexusContactFormData = (data: FormData | DeprecatedFormData): data is FormData => {
    return 'countryCode' in data;
};

export const useContactForm = ({
    ownerId,
    onValid,
    onInvalid,
    onRequestSuccess,
    onRequestFailure,
}: UseContactFormArguments): UseContactFormReturnType => {
    const [, executeMutation] = useMutation(SEND_MESSAGE_OWNER_MUTATION);
    const { user } = useLazyUser();
    const [t] = useTranslations();
    const {
        featureFlags: { isNexusSellerPageEnabled },
    } = useSiteSettings();
    const { onSubmitTrackingHandler } = useContactFormTracking();

    const { openSnackbar: openSnackbarSuccess } = useSnackbarState(SNACKBAR_ID.success);
    const { openSnackbar: openSnackbarError } = useSnackbarState(SNACKBAR_ID.error);

    const defaultValues: FormData | DeprecatedFormData = {
        name: '',
        email: '',
        phone: '',
        message: '',
    };

    if (isNexusContactFormData(defaultValues)) {
        defaultValues.countryCode = `+${SITE_CONFIG.phoneNumbers.countryCode}`;
    }

    const form = useForm<DeprecatedFormData | FormData>({
        mode: 'onTouched',
        defaultValues,
    });

    const submit = form.handleSubmit(async (data) => {
        onValid?.();

        try {
            const { message, name, email, phone } = data;

            let parsedPhone = phone;
            // TODO: delete the condition and leave the condition body in https://naspersclassifieds.atlassian.net/browse/EURE-28574
            if (isNexusContactFormData(data)) {
                const { countryCode } = data;
                parsedPhone = phone ? `${countryCode}${phone}` : '';
            }

            const {
                data: responseData,
                error,
                operation,
            } = await executeMutation({
                input: { ownerId, phone: parsedPhone, message, name, email: email || user?.email || '' },
            });

            assertGraphqlResponse({
                data: responseData?.sendMessageOwner,
                expectedTypenames: ['MessageOwnerResponse'],
                graphqlError: error,
                onTypeMismatch: 'THROW_EXCEPTION',
                logErrorPrefix: '[SellerPage][useContactForm]',
                operation,
            });

            onRequestSuccess?.();

            if (isNexusSellerPageEnabled) {
                openSnackbarSuccess();
                onSubmitTrackingHandler();
            } else {
                // TODO: to be removed in https://naspersclassifieds.atlassian.net/browse/EURE-2857
                toast.success(t('frontend.companies.contact-form.toast-success-message'));
            }

            form.reset({
                email: user?.email,
                message: '',
                name: '',
                phone: '',
                countryCode: `+${SITE_CONFIG.phoneNumbers.countryCode}`,
            });
        } catch (error) {
            onRequestFailure?.();

            if (isNexusSellerPageEnabled) {
                openSnackbarError();
            } else {
                // TODO: to be removed in https://naspersclassifieds.atlassian.net/browse/EURE-2857
                toast.error(t('frontend.companies.contact-form.toast-error-message'));
            }

            logError('[SellerPage] An error occurred in the contact form', { error });
        }
    }, onInvalid);

    return { ...form, submit };
};
