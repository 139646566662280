import { useTracking } from '@lib/tracking/useTracking';

import type { DeprecatedFormData } from '../types/formData';

export const useFormEventHandlersFactory = ({
    eventTouchPointButton,
    additionalTrackingData = {},
}: {
    eventTouchPointButton: string;
    additionalTrackingData?: Record<string, unknown>;
}): {
    handleFormValid: () => void;
    handleFormInvalid: () => void;
    handleFormRequestSuccess: () => void;
    handleFormRequestFailure: () => void;
    handleFieldFocus: (fieldName: keyof DeprecatedFormData) => void;
    handleFieldBlur: (fieldName: keyof DeprecatedFormData, isValid: boolean) => void;
} => {
    const { trackEvent } = useTracking();

    const data = {
        touch_point_button: eventTouchPointButton,
        ...additionalTrackingData,
    };

    const handleFormValid = (): void => {
        trackEvent('reply_message_click', data);
    };

    const handleFormInvalid = (): void => {
        trackEvent('reply_message_click', data);
        trackEvent('reply_message_click_error', data);
    };

    const handleFormRequestSuccess = (): void => {
        trackEvent('reply_message_sent', data);
    };

    const handleFormRequestFailure = (): void => {
        trackEvent('reply_message_sent_error', data);
    };

    const handleFieldFocus = (fieldName: keyof DeprecatedFormData): void => {
        const eventNames: Record<keyof DeprecatedFormData, string> = {
            name: 'reply_message_name_start',
            email: 'reply_message_email_start',
            phone: 'reply_message_phone_start',
            message: 'reply_message_text_start',
        };
        trackEvent(eventNames[fieldName], data);
    };

    const handleFieldBlur = (fieldName: keyof DeprecatedFormData, isValid: boolean): void => {
        const eventAction = isValid ? 'valid' : 'error';
        const eventNames: Record<keyof DeprecatedFormData, string> = {
            name: `reply_message_name_${eventAction}`,
            email: `reply_message_email_${eventAction}`,
            phone: `reply_message_phone_${eventAction}`,
            message: `reply_message_text_${eventAction}`,
        };

        trackEvent(eventNames[fieldName], data);
    };

    return {
        handleFormValid,
        handleFormInvalid,
        handleFormRequestSuccess,
        handleFormRequestFailure,
        handleFieldFocus,
        handleFieldBlur,
    };
};
