import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import type { MutableRefObject } from 'react';
import { useCallback, useState } from 'react';

export const useIsRenderedInViewport = (ref: MutableRefObject<HTMLElement | null>): boolean => {
    const [isInViewportOnInit, setIsInViewportOnInit] = useState(false);

    const intersectionCallback = useCallback((entry?: IntersectionObserverEntry, observer?: IntersectionObserver) => {
        if (!entry || !observer) {
            return;
        }

        if (entry.isIntersecting) {
            setIsInViewportOnInit(true);
        }

        observer.unobserve(entry.target);
        observer.disconnect();
    }, []);

    useIntersection(ref, intersectionCallback, {
        threshold: 0,
    });

    return isInViewportOnInit;
};
