import { FieldError } from '@domains/shared/components/FieldError/FieldError';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FocusEventHandler, FormEventHandler, JSX } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { DeprecatedFormData } from '../types/formData';
import { Container, InfoWrapper, MessageCounterWrapper, StyledTextArea } from './MessageField.theme';

const MAX_MESSAGE_LENGTH = 2000;

interface Props {
    onFocus?: (fieldName: keyof DeprecatedFormData) => void;
    onBlur?: (fieldName: keyof DeprecatedFormData, isValid: boolean) => void;
}

export const MessageField = ({ onFocus, onBlur }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { register, formState } = useFormContext<DeprecatedFormData>();
    const [characterCount, setCharacterCount] = useState(0);

    const fieldName = 'message';
    const alertId = 'message-error-alert';
    const error = formState.errors.message;

    const { onChange, ...registeredField } = useMemo(() => {
        return register(fieldName, {
            required: t('frontend.ad.contact-form.text-required-error'),
            maxLength: { value: MAX_MESSAGE_LENGTH, message: t('frontend.ad.contact-form.text-too-long-error') },
        });
    }, [register, t]);

    const handleChange: FormEventHandler<HTMLTextAreaElement> = useCallback(
        (event) => {
            const target = event.target as HTMLTextAreaElement;

            onChange(event);
            setCharacterCount(target.value.length);
        },
        [onChange],
    );

    const handleFocus: FocusEventHandler<HTMLTextAreaElement> = useCallback((): void => {
        onFocus?.(fieldName);
    }, [onFocus]);

    const handleBlur: FocusEventHandler<HTMLTextAreaElement> = useCallback(
        async (event): Promise<void> => {
            await registeredField.onBlur(event);
            // Note: We're not using `error` const because we need fresh reference.
            onBlur?.(fieldName, !formState.errors.message);
        },
        [formState.errors.message, onBlur, registeredField],
    );

    return (
        <Container>
            <StyledTextArea
                {...registeredField}
                aria-errormessage={error ? alertId : undefined}
                aria-label={t('frontend.ad.contact-form.field-text')}
                aria-required="true"
                onChange={handleChange}
                placeholder={t('frontend.ad.contact-form.field-placeholder-text')}
                variant={error ? 'invalid' : 'default'}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <InfoWrapper>
                <div>{error ? <FieldError id={alertId} error={error.message} /> : null}</div>
                <MessageCounterWrapper>
                    {characterCount}/{MAX_MESSAGE_LENGTH}
                </MessageCounterWrapper>
            </InfoWrapper>
        </Container>
    );
};
