import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Wrapper = styled.header`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-right: 36px;
    margin-bottom: 24px;
    gap: 12px;
`;

export const StyledParagraph = styled.p`
    margin: 0;
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.bold};
`;

export const StyledList = styled.ul`
    grid-column-start: 2;
    margin: 0;
    padding: 0;
    list-style: none;
`;
