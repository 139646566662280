import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ComponentProps, JSX } from 'react';

import { StyledImage } from './CompanyImage.theme';

type EssentialImageProps = Omit<ComponentProps<typeof StyledImage>, 'src'>;

interface Props extends EssentialImageProps {
    src: string | null | undefined;
    companyName: string;
    width: string;
    height: string;
}

export const CompanyImage = ({ src, companyName, width, height, ...props }: Props): JSX.Element => {
    const { staticAssetsPrefix } = getPublicEnvConfig();
    const [t] = useTranslations();

    const sourceOrPlaceholder = src ?? `${staticAssetsPrefix}/images/companies/companyImagePlaceholder.webp`;
    const altText = src ? `${companyName} ${t('frontend.global.image.logo')}` : '';

    return <StyledImage src={sourceOrPlaceholder} alt={altText} width={width} height={height} {...props} />;
};
