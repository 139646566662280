import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const StyledImage = styled.img<{ hasBadge?: boolean; width?: string; height?: string }>`
    display: block;
    object-fit: ${({ hasBadge = false }): CSSProperties['objectFit'] => (hasBadge ? 'cover' : 'contain')};
    width: ${({ width }): CSSProperties['width'] => width ?? '252px'};
    height: ${({ height }): CSSProperties['height'] => height ?? '127px'};

    @media (max-width: ${theme.breakpoints.lg}) {
        width: ${({ width }): CSSProperties['width'] => width ?? '120px'};
        height: ${({ height }): CSSProperties['height'] => height ?? '80px'};
    }
`;
