import { SITE_CONFIG } from '@config/siteConfig';
import { Button } from '@domains/shared/components/Button/Button';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { GdprNote } from '@domains/shared/components/GdprNote/GdprNote';
import { transformGdprDetails } from '@domains/shared/components/GdprNote/helpers/transformGdprDetails';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX, ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';

import { Container, StyledForm } from './CoreContactForm.theme';
import { EmailField } from './fields/EmailField';
import { MessageField } from './fields/MessageField';
import { NameField } from './fields/NameField';
import { PhoneNumberField } from './fields/PhoneNumberField';
import { useContactForm } from './hooks/useContactForm';
import type { DeprecatedFormData } from './types/formData';

interface Props {
    ownerId: string;
    header?: ReactNode;
    shouldUseGdptPopover?: boolean;
    onValid?: () => void;
    onInvalid?: () => void;
    onRequestSuccess?: () => void;
    onRequestFailure?: () => void;
    onFieldFocus?: (fieldName: keyof DeprecatedFormData) => void;
    onFieldBlur?: (fieldName: keyof DeprecatedFormData, isValid: boolean) => void;
}

export const CoreContactForm = ({
    ownerId,
    header,
    shouldUseGdptPopover,
    onValid,
    onInvalid,
    onRequestSuccess,
    onRequestFailure,
    onFieldFocus,
    onFieldBlur,
}: Props): JSX.Element => {
    const form = useContactForm({
        ownerId,
        onValid,
        onInvalid,
        onRequestSuccess,
        onRequestFailure,
    });
    const [t] = useTranslations();

    return (
        <Container data-testid="core-seller-contact-form">
            {header ?? null}
            <FormProvider {...form}>
                <StyledForm onSubmit={form.submit} autoComplete="off">
                    <NameField onFocus={onFieldFocus} onBlur={onFieldBlur} />
                    <EmailField onFocus={onFieldFocus} onBlur={onFieldBlur} />
                    <PhoneNumberField onFocus={onFieldFocus} onBlur={onFieldBlur} />
                    <MessageField onFocus={onFieldFocus} onBlur={onFieldBlur} />
                    <GdprNote
                        intro={t('frontend.companies.contact-form.gdpr-intro')}
                        details={transformGdprDetails({
                            description: t('frontend.companies.contact-form.gdpr-details'),
                            privacyPolicyLink: SITE_CONFIG.privacyPolicyLink,
                            termsAndConditionsLink: SITE_CONFIG.termsAndConditionsLink,
                        })}
                        shouldUsePopover={shouldUseGdptPopover}
                        data-cy="seller-contact-form-gdpr"
                    />
                    <Button variant={ButtonVariant.Primary} type="submit" disabled={form.formState.isSubmitting}>
                        {t('frontend.ad.contact-form.send-message')}
                    </Button>
                </StyledForm>
            </FormProvider>
        </Container>
    );
};
