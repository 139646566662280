import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { JSX } from 'react';

import { getPageHref } from './getPageHref';
import { ActiveControl, ControlLink } from './PaginationControls.theme';

interface Props {
    isActive: boolean;
    page: number;
    onPageChange(page: number, source: 'link' | 'button'): void;
    shouldShallowLink?: boolean;
}

export const PaginationControlsLink = ({
    isActive,
    page,
    onPageChange,
    shouldShallowLink,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const router = useRouter();
    const path = router.asPath;

    return isActive ? (
        <ActiveControl
            aria-current={true}
            aria-label={`${t('frontend.pagination-controls.go-to-page')} ${page}`}
            data-cy={`pagination.go-to-page-${page}`}
        >
            {page}
        </ActiveControl>
    ) : (
        <Link href={getPageHref({ page, path })} legacyBehavior passHref shallow={shouldShallowLink} scroll>
            <ControlLink
                onClick={(): void => onPageChange(page, 'link')}
                aria-label={`${t('frontend.pagination-controls.go-to-page')} ${page}`}
                data-cy={`pagination.go-to-page-${page}`}
            >
                {page}
            </ControlLink>
        </Link>
    );
};
