import { repeatItem } from '@domains/shared/helpers/repeatItem';

import { PAGINATION_SEPARATOR } from './PaginationControlsItem';

const getRangeOfPagesNearActive = (start = 0, end = 0): number[] => {
    if (start >= end + 1) {
        return [0];
    }

    return repeatItem(end - start + 1, (id) => start + id);
};

type PaginationItems = (number | typeof PAGINATION_SEPARATOR)[];
type GetPaginationPages = (activePage: number, pagesCount: number) => PaginationItems;

export const getPaginationPages: GetPaginationPages = (activePage, pagesCount) => {
    let startPage = activePage - 1;
    let endPage = activePage + 1;

    if (startPage === 0) {
        startPage += 1;
        endPage += 1;
    }
    const minPagesCount = pagesCount - 2 > 0 ? pagesCount - 2 : 1;

    let pagination: PaginationItems =
        activePage > 2
            ? getRangeOfPagesNearActive(Math.min(startPage, minPagesCount), Math.min(endPage, pagesCount))
            : getRangeOfPagesNearActive(1, Math.min(pagesCount, 3));

    // How works withDots func:
    // If pagination.length(3) is equal to pages count
    // return just first argument(value) in array
    // otherwise return second argument(pair - array with value and separator)
    const withDots = (value: number, pair: PaginationItems): PaginationItems =>
        pagination.length !== pagesCount && pagination[0] !== 2 ? pair : [value];

    if (pagination[0] !== 1) {
        const dots = withDots(1, [1, PAGINATION_SEPARATOR]);

        pagination = [...dots, ...pagination];
    }
    if (Number(pagination[pagination.length - 1]) < pagesCount) {
        if (pagination[pagination.length - 1] === pagesCount - 1) {
            pagination = [...pagination, pagesCount];
        } else {
            const dots = withDots(pagesCount, [PAGINATION_SEPARATOR, pagesCount]);

            pagination = [...pagination, ...dots];
        }
    }

    return pagination;
};
