import Modal from '@domains/shared/components/Modal/Modal';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const StyledModal = styled(Modal)`
    width: 100%;

    @media (min-width: ${BREAKPOINT.md}) {
        max-width: 376px;
    }
`;
