import { CLIPBOARD_THEME } from '@domains/clipboard/theme/[[[SITECODE]]]';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';
import type { CSSProperties } from 'react';

const META_MAP_MAX_WIDTH_MULTIPLE_ADS = '244px';
const META_MAX_WIDTH_DEFAULT = '350px';

export const CommonMeta = styled.div<{ isAdRemoved?: boolean }>`
    position: relative;
    grid-area: meta;
    width: auto;
    max-width: ${META_MAX_WIDTH_DEFAULT};
    margin: 0;
    color: ${({ theme, isAdRemoved }): CSSProperties['color'] =>
        theme.deprecated.domains.shared.listingMetaItem.color.params[isAdRemoved ? 'removed' : 'active']};

    @media (min-width: ${BREAKPOINT.md}) {
        max-width: unset;
        margin-top: 4px;
    }

    > span:first-of-type {
        min-height: 24px;
        margin: 0;
        padding-left: 0;
        color: ${({ theme, isAdRemoved }): CSSProperties['color'] =>
            theme.deprecated.domains.shared.listingMetaItem.color.price[isAdRemoved ? 'removed' : 'active']};
        font-size: ${SIZE.h5};
        font-weight: ${WEIGHT.bold};
        line-height: ${LINE_HEIGHT.x2};
    }

    > span:nth-of-type(3n) {
        clear: left;

        @media (min-width: ${BREAKPOINT.md}) {
            clear: none;
        }
    }
`;

export const MapMeta = styled(CommonMeta)<{ shouldUseStylesForSingleElement?: boolean }>`
    max-width: ${({ shouldUseStylesForSingleElement }): string =>
        shouldUseStylesForSingleElement ? META_MAX_WIDTH_DEFAULT : META_MAP_MAX_WIDTH_MULTIPLE_ADS};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (min-width: ${BREAKPOINT.md}) {
        max-width: unset;
        white-space: normal;
    }

    > span:first-of-type {
        margin-top: ${({ shouldUseStylesForSingleElement }): string => (shouldUseStylesForSingleElement ? '8px' : '0')};
        font-size: ${({ shouldUseStylesForSingleElement }): string =>
            shouldUseStylesForSingleElement ? SIZE.h5 : SIZE.p1};

        @media (min-width: ${BREAKPOINT.md}) {
            font-size: ${SIZE.h5};
        }
    }

    > span:last-of-type {
        padding-right: 0;
    }
`;

export const CommonMetaItem = styled.span`
    display: inline-block;
    margin-top: auto;
    padding-top: 4px;
    padding-right: 8px;
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.bold};
    line-height: ${LINE_HEIGHT.x2};
    vertical-align: baseline;

    &:empty {
        padding: 0;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        padding-right: 12px;
    }
`;

export const MapMetaItem = styled(CommonMetaItem)<{
    shouldUseStylesForSingleElement?: boolean;
}>`
    padding-top: 0;
    font-size: ${({ shouldUseStylesForSingleElement }): string =>
        shouldUseStylesForSingleElement ? SIZE.p3 : SIZE.p5};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p3};
    }
`;

export const CommonRent = styled.span<{ isInlineWithPrice?: boolean }>`
    display: ${({ isInlineWithPrice }): string => (isInlineWithPrice ? 'inline-block' : 'block')};
    margin-top: auto;
    margin-left: ${({ isInlineWithPrice }): string => (isInlineWithPrice ? '8px' : '0')};
    padding-right: 8px;
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.regular};
`;

export const NewPriceLabel = styled.div<{ isPriceUp: boolean }>`
    margin-bottom: -4px;
    color: ${({ isPriceUp }): CSSProperties['color'] =>
        isPriceUp
            ? CLIPBOARD_THEME.savedAds.card.priceIncreaseInfo.textColor
            : CLIPBOARD_THEME.savedAds.card.priceDropInfo.textColor};
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.bold};

    @media (min-width: ${BREAKPOINT.md}) {
        margin-bottom: 0;
    }
`;

export const MapRent = styled(CommonRent)`
    font-size: ${SIZE.p5};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p4};
    }
`;

export const NewPriceBadge = styled.span<{ isPriceUp: boolean; shouldShowRentPrice: boolean }>`
    margin: 0 0 0 8px;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: ${({ isPriceUp }): CSSProperties['backgroundColor'] =>
        isPriceUp
            ? CLIPBOARD_THEME.savedAds.card.priceIncreaseInfo.backgroundColor
            : CLIPBOARD_THEME.savedAds.card.priceDropInfo.backgroundColor};
    color: ${({ isPriceUp }): CSSProperties['color'] =>
        isPriceUp
            ? CLIPBOARD_THEME.savedAds.card.priceIncreaseInfo.textColor
            : CLIPBOARD_THEME.savedAds.card.priceDropInfo.textColor};
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.bold};

    @media (min-width: ${BREAKPOINT.md}) {
        margin: ${({ shouldShowRentPrice }): string => (shouldShowRentPrice ? '0 0 0 0' : '0 0 0 8px')};
    }
`;
