import { TextArea } from '@domains/shared/components/TextArea/TextArea';
import styled from '@emotion/styled';
import { SIZE } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTextArea = styled(TextArea)`
    width: 100%;
    padding: 8px;
    resize: none;
`;

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const MessageCounterWrapper = styled.div`
    margin-top: 8px;
    font-size: ${SIZE.p4};
`;
