import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { GREY_UPDATED } from '@lib/styles/partials/colors';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    margin-top: 4px;
    color: ${GREY_UPDATED.x500};
    font-size: ${SIZE.p5};

    @media (min-width: ${BREAKPOINT.md}) {
        margin-top: 2px;
    }
`;

export const PriceValue = styled.span`
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.bold};
`;

export const InfoIcon = styled(Icon)`
    margin: 2px 4px 0 0;
    font-size: 10px;

    @media (min-width: ${BREAKPOINT.md}) {
        margin-top: 4px;
    }
`;
