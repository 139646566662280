import { Input } from '@domains/shared/components/Input/Input';
import styled from '@emotion/styled';
import { WEIGHT } from '@lib/styles/partials/typography';

export const StyledInput = styled(Input)`
    ${({ theme, readOnly }): string =>
        readOnly
            ? `
                border-color: ${theme.deprecated.domains.shared.input.disabled.borderColor};
                background-color: ${theme.deprecated.domains.shared.input.disabled.backgroundColor};
                color: ${theme.deprecated.domains.shared.input.disabled.color};
                font-style: normal;
                font-weight: ${WEIGHT.regular};
                cursor: not-allowed;
        
                &:hover {
                    border-color: ${theme.deprecated.domains.shared.input.default.borderColor};
                }
               `
            : ''};
`;
