import { createRoomsNumberLabel } from '@domains/shared/helpers/createRoomsNumberLabel';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { ListingItemPrice } from '@type/pricing/listingItemPrice';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';

import { META_TYPE } from './constants';
import type {
    MetaArea,
    MetaInvestmentEstimatedDelivery,
    MetaInvestmentUnits,
    MetaInvestmentUnitsRooms,
    MetaPricePerUnit,
} from './types';

export const checkIsPriceListing = (argument: unknown): argument is ListingItemPrice => {
    return (
        !!argument &&
        Object.prototype.hasOwnProperty.call(argument, 'currency') &&
        Object.prototype.hasOwnProperty.call(argument, 'value')
    );
};
export const checkIsMetaArea = (argument: unknown): argument is MetaArea => {
    return (
        !!argument &&
        Object.prototype.hasOwnProperty.call(argument, 'unit') &&
        Object.prototype.hasOwnProperty.call(argument, 'value')
    );
};
export const checkIsMetaAreaRange = (argument: unknown): argument is [MetaArea, MetaArea] => {
    return (
        !!argument &&
        Object.prototype.hasOwnProperty.call(argument, 'length') &&
        Array.isArray(argument) &&
        (argument as { length: number }).length === 2
    ); // [from, to]
};
export const checkIsMetaPricePerUnit = (argument: unknown): argument is MetaPricePerUnit => {
    return (
        !!argument &&
        Object.prototype.hasOwnProperty.call(argument, 'unit') &&
        Object.prototype.hasOwnProperty.call(argument, 'price')
    );
};
export const checkIsMetaInvestmentUnits = (argument: unknown): argument is MetaInvestmentUnits => {
    return (
        !!argument &&
        typeof argument === 'object' &&
        (argument as Record<string, unknown>).type === META_TYPE.investmentUnits
    );
};
export const checkIsMetaInvestmentUnitsRooms = (argument: unknown): argument is MetaInvestmentUnitsRooms => {
    return (
        !!argument &&
        typeof argument === 'object' &&
        (argument as Record<string, unknown>).type === META_TYPE.investmentUnitsRooms
    );
};
export const checkIsMetaInvestmentEstimatedDelivery = (
    argument: unknown,
): argument is MetaInvestmentEstimatedDelivery => {
    return (
        !!argument &&
        typeof argument === 'object' &&
        (argument as Record<string, unknown>).type === META_TYPE.investmentEstimatedDelivery
    );
};
export const getRoomsNumberLabel = ({
    t,
    lang,
    roomsNumber,
    shouldUseTypologyInsteadOfNumberOfRooms,
}: {
    t: Translator;
    lang: Locale;
    roomsNumber: RoomsNumber | null;
    shouldUseTypologyInsteadOfNumberOfRooms?: boolean;
}): string => {
    const roomsNumberLabelVariant = shouldUseTypologyInsteadOfNumberOfRooms ? 'typology' : undefined;

    return roomsNumber ? createRoomsNumberLabel(t, lang, roomsNumber, roomsNumberLabelVariant) : '';
};
