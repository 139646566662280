import { useTracking } from '@lib/tracking/useTracking';

interface UseContactFormReturnType {
    nameInputOnClickHandler: () => void;
    mailInputOnClickHandler: () => void;
    phoneInputOnClickHandler: () => void;
    messageInputOnClickHandler: () => void;
    onSubmitTrackingHandler: () => void;
}

export const useContactFormTracking = (): UseContactFormReturnType => {
    const { trackEvent } = useTracking();

    const nameInputOnClickHandler = (): void => {
        trackEvent('reply_message_name_start', {
            touch_point_button: 'contact_form',
        });
    };

    const mailInputOnClickHandler = (): void => {
        trackEvent('reply_message_email_start', {
            touch_point_button: 'contact_form',
        });
    };

    const phoneInputOnClickHandler = (): void => {
        trackEvent('reply_message_phone_start', {
            touch_point_button: 'contact_form',
        });
    };

    const messageInputOnClickHandler = (): void => {
        trackEvent('reply_message_text_start', {
            touch_point_button: 'contact_form',
        });
    };

    const onSubmitTrackingHandler = (): void => {
        trackEvent('reply_message_sent', {
            touch_point_button: 'contact_form',
        });
    };

    return {
        nameInputOnClickHandler,
        mailInputOnClickHandler,
        phoneInputOnClickHandler,
        messageInputOnClickHandler,
        onSubmitTrackingHandler,
    };
};
