import styled from '@emotion/styled';

export const Container = styled.div`
    padding: 16px;
    background-color: white;
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;

    & > * + * {
        margin-top: 16px;
    }
`;
