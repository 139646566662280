import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import type { JSX } from 'react';

import { Container, InfoIcon, PriceValue } from './LowestPriceBeforeDiscount.theme';

interface Props {
    price: string;
}

export const LowestPriceBeforeDiscount = ({ price }: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container>
            <InfoIcon icon={faExclamationCircle} />
            <div>
                {replacePlaceholders(
                    t('frontend.search.listing-meta-item.lowest-price-label', { value: price }),
                    (value) => (
                        <PriceValue>{value}</PriceValue>
                    ),
                )}
            </div>
        </Container>
    );
};
