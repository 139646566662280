import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

import { Control, Dots } from './PaginationControls.theme';
import { PaginationControlsLink } from './PaginationControlsLink';

export const PAGINATION_SEPARATOR = '...';

interface Props {
    page: number | typeof PAGINATION_SEPARATOR;
    activePage: number;
    shouldPagesBeLink?: boolean;
    shouldShallowLink?: boolean;
    onPageChange(page: number, source: 'link' | 'button'): void;
}

export const PaginationControlsItem = ({
    page,
    activePage,
    shouldPagesBeLink,
    onPageChange,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();

    if (page === PAGINATION_SEPARATOR) {
        return <Dots>{page}</Dots>;
    } else {
        return shouldPagesBeLink ? (
            <PaginationControlsLink
                isActive={page === activePage}
                onPageChange={onPageChange}
                page={page}
                shouldShallowLink={shouldPagesBeLink}
            />
        ) : (
            <Control
                onClick={(): void => onPageChange(page, 'button')}
                isActive={page === activePage}
                aria-current={page === activePage}
                aria-label={`${t('frontend.pagination-controls.go-to-page')} ${page}`}
                data-cy={`pagination.go-to-page-${page}`}
            >
                {page}
            </Control>
        );
    }
};
