import type { GenericGraphQLError } from '@lib/graphql/error';
import { gql } from 'urql';

interface MessageOwnerResponseData {
    __typename: 'MessageOwnerResponse';
    ownerId: string;
    email: string;
    name: string;
    phone: string | null;
    message: string;
}

interface Variables {
    input: {
        ownerId: string;
        email: string;
        name: string;
        phone: string | null;
        message: string;
    };
}

export const SEND_MESSAGE_OWNER_MUTATION = gql<
    {
        sendMessageOwner: MessageOwnerResponseData | GenericGraphQLError;
    },
    Variables
>`
    mutation SendMessageOwnerMutation($input: MessageOwnerInput!) {
        sendMessageOwner(input: $input) {
            __typename
            ... on MessageOwnerResponse {
                ownerId
                name
                email
                phone
                message
            }
        }
    }
`;
