import type { InputHTMLAttributes, JSX, Ref } from 'react';
import { forwardRef } from 'react';

import { HiddenNativeCheckbox, Switch, Thumb } from './Toggle.theme';

type NativeCheckboxProps = InputHTMLAttributes<HTMLInputElement>;

interface Props extends Omit<NativeCheckboxProps, 'autoComplete'> {
    id: string;
    ariaLabel?: string;
}

const BaseToggle = ({ className, id, ariaLabel, ...inputProps }: Props, ref?: Ref<HTMLInputElement>): JSX.Element => {
    // Disable autoComplete for controlled input (state passed via prop)
    // Otherwise the behavior is bugged when the browser saves input state and does not trigger onChange
    const isControlledInput = inputProps.checked !== undefined;
    const autoComplete = isControlledInput ? 'off' : undefined;

    return (
        <>
            <HiddenNativeCheckbox type="checkbox" id={id} autoComplete={autoComplete} ref={ref} {...inputProps} />
            <Switch data-testid="switch" aria-label={ariaLabel} className={className} htmlFor={id}>
                <Thumb />
            </Switch>
        </>
    );
};

export const Toggle = forwardRef(BaseToggle);
