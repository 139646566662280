import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';

import { Container, Price, StyledTooltipIcon } from './PriceBeforeDiscount.theme';

interface Props {
    price: string;
}

export const PriceBeforeDiscount: FC<Props> = ({ price }) => {
    const [t] = useTranslations();

    return (
        <Container>
            <Price>{price}</Price>
            <StyledTooltipIcon description={t('frontend.global.price.lowest-within-last-30-days')} />
        </Container>
    );
};
